.slide-wrap {
  width: 650px;
  position: relative;
}

@-webkit-keyframes slidetounlock {
  0% {
    background-position: -400px 0;
  }
  50% {
    background-position: -100px 0;
  }
  100% {
    background-position: 200px 0;
  }
}

label {
  width: 150px;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(0, #4d4d4d),
    color-stop(0.4, #4d4d4d),
    color-stop(0.5, white),
    color-stop(0.6, #4d4d4d),
    color-stop(1, #4d4d4d)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-animation: slidetounlock 5s infinite;
  color: #4d4d4d;
  font-size: 60px;
  font-family: "HelveticaNeue-Light";
  line-height: 100px;
  height: 100px;
  position: absolute;
  left: 200px;
}

input[type="range"] {
  -webkit-appearance: none !important;
  -webkit-border-radius: 30px;
  border-radius: 30px;
  background-color: #ededed;
  overflow: hidden;
  -webkit-user-select: none;
  width: 80%;
  height: 50px;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none !important;
  background-color: "white";
  width: 40px;
  height: 40px;
  line-height: 1;
  display: inline-block;
}
